import React from 'react';
import '../styles/tariff-banner.scss';

export const TariffBanner = () => {
  return <div className='tariff-banner' style={{ paddingBottom: '10px'}}>
    <div className='tariff-banner__header'>Уважаемый клиент!</div>
    <div className='tariff-banner__content'>
      Сообщаем, что с 22 апреля 2025 года изменится стоимость тарифов КАССАТКА.<br />
      <br/>
      Текущие активные подписки действуют до срока их окончания.<br/>
    </div>
  </div>
}